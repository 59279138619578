import { Checkbox as KobalteCheckbox } from "@kobalte/core/checkbox"
import type { Accessor, Setter } from "solid-js"
import { Check } from "./check"
import {
	checkboxClass,
	checkboxControlClass,
	checkboxLabelClass,
} from "./checkbox.css"

type Props = {
	checked: Accessor<boolean>
	onChange: Setter<boolean>
	label: string
}
export function Checkbox(props: Props) {
	return (
		<KobalteCheckbox
			checked={props.checked()}
			onChange={props.onChange}
			class={checkboxClass}
		>
			<KobalteCheckbox.Input />
			<KobalteCheckbox.Control class={checkboxControlClass}>
				<KobalteCheckbox.Indicator>
					<Check />
				</KobalteCheckbox.Indicator>
			</KobalteCheckbox.Control>
			<KobalteCheckbox.Label class={checkboxLabelClass}>
				{props.label}
			</KobalteCheckbox.Label>
		</KobalteCheckbox>
	)
}
