import { Tabs } from "@kobalte/core/tabs"
import { writeClipboard } from "@solid-primitives/clipboard"
import { promiseTimeout } from "@solid-primitives/promise"
import { createScheduled, debounce } from "@solid-primitives/scheduled"
import {
	Match,
	Show,
	Switch,
	createMemo,
	createResource,
	createSignal,
} from "solid-js"
import { ulid } from "ulidx"
import { Checkbox } from "./checkbox"
import {
	copyToClipboardClass,
	copyToClipboardSVGClass,
	debugGridRecipe,
	h2Recipe,
	leftPaneClass,
	preClass,
	preCodeClass,
	resultClass,
	resultContentClass,
	rightPaneClass,
	sectionClass,
	tabsClass,
	tabsContentClass,
	tabsContentContainerRecipe,
	tabsListClass,
	tabsTriggerRecipe,
} from "./sandbox.css"
import { SelectField } from "./select"
import { TextField } from "./textField"

const fetcherUrl = import.meta.env.PUBLIC_HANDINGER_BACKEND_OPEN_URL
const DEFAULT_URLS = [
	"https://www.paulgraham.com/foundermode.html",
	"https://en.wikipedia.org/wiki/Meiji_Restoration",
	"https://x.com/miramurati/status/1726542556203483392",
	"https://www.amazon.com/Designing-Data-Intensive-Applications-Reliable-Maintainable/dp/1449373321",
	"https://stackoverflow.com/questions/1732348/regex-match-open-tags-except-xhtml-self-contained-tags",
	"https://www.ribbonfarm.com/2010/07/26/a-big-little-idea-called-legibility/",
]

type Source = {
	url: string
	apiKey: string
}

function CopyToClipboard() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class={copyToClipboardSVGClass}
		>
			<title>Copy to clipboard</title>
			<rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
			<path d="M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M16 4h2a2 2 0 0 1 2 2v4m1 4H11" />
			<path d="m15 10-4 4 4 4" />
		</svg>
	)
}

function isValidUrl(url: string) {
	try {
		new URL(url)
		return true
	} catch (err) {
		return false
	}
}

export default function Sandbox(props: { apiKey?: string }) {
	const [targetUrl, setTargetUrl] = createSignal(DEFAULT_URLS[0])
	const [handler, setHandler] = createSignal("markdown")
	const [language, setLanguage] = createSignal("bash")
	const [copied, setCopied] = createSignal(false)
	const [truncatedBody, setTruncatedBody] = createSignal(false)
	const [readability, setReadability] = createSignal(true)
	const [inline, setInline] = createSignal(false)
	const apiKey = createMemo(() => props.apiKey ?? ulid())

	const scheduled = createScheduled((fn) => debounce(fn, 1000))
	const debouncedTargetUrl = createMemo((prev: string | undefined) =>
		scheduled() ? targetUrl() : prev || targetUrl(),
	)
	const finalUrl = createMemo(() => {
		const baseUrl = `${fetcherUrl}/${handler()}`
		const params = new URLSearchParams()

		params.append(
			"url",
			isValidUrl(debouncedTargetUrl()) ? debouncedTargetUrl() : DEFAULT_URLS[0],
		)

		if (handler() === "meta" && truncatedBody()) {
			params.append("truncatedBody", "true")
		}

		if ((handler() === "markdown" || handler() === "html") && readability()) {
			params.append("readability", "true")
		}

		if ((handler() === "markdown" || handler() === "html") && inline()) {
			params.append("inline", "true")
		}

		const queryString = params.toString()
		return `${baseUrl}?${queryString}`
	})

	const fetchUrl = createMemo(() => ({
		url: finalUrl(),
		apiKey: apiKey(),
	}))

	const [response] = createResource(fetchUrl, async (source: Source) => {
		const res = await fetch(source.url, {
			headers: {
				Authorization: `Bearer ${source.apiKey}`,
			},
		})

		switch (handler()) {
			case "meta":
				return await res.json()
			default:
				return await res.text()
		}
	})

	const snippet = createMemo(() => {
		switch (language()) {
			case "bash":
				return `curl "${finalUrl()}" -H "Authorization: Bearer ${apiKey()}"`
			case "js":
				return `fetch(
  '${finalUrl()}',
  { headers: { 'Authorization': 'Bearer ${apiKey()}' } }
)`
			case "python":
				return `import requests

response = requests.get(
    '${finalUrl()}',
    headers={'Authorization': 'Bearer ${apiKey()}'}
)`
			case "go":
				return `package main

import (
	"fmt"
	"net/http"
)

func main() {
	url := "${finalUrl()}"
	req, _ := http.NewRequest("GET", url, nil)
	req.Header.Set("Authorization", "Bearer ${apiKey()}")

	client := &http.Client{}
	resp, err := client.Do(req)
	if err != nil {
		return
	}
	defer resp.Body.Close()
}`
			case "ruby":
				return `require 'net/http'

url = URI('${finalUrl()}')
response = Net::HTTP.start(url.host, url.port, use_ssl: true) do |http|
  request = Net::HTTP::Get.new(url)
  request['Authorization'] = 'Bearer ${apiKey()}'

  http.request(request)
end
json = JSON.parse(response.body)`
			default:
				return "unsupported"
		}
	})

	const color = createMemo(() => {
		switch (handler()) {
			case "markdown":
				return "gold"
			case "image":
				return "violet"
			case "meta":
				return "brick"
			case "html":
				return "emerald"
			default:
				return "mold"
		}
	})

	async function onCopy() {
		writeClipboard(snippet())
		setCopied(true)
		await promiseTimeout(2_000)
		setCopied(false)
	}

	return (
		<section class={sectionClass}>
			<div class={leftPaneClass}>
				<h2 class={h2Recipe({ color: color() })}>░ options</h2>
				<Tabs value={handler()} onChange={setHandler} class={tabsClass}>
					<Tabs.List class={tabsListClass}>
						<Tabs.Trigger
							value="markdown"
							class={tabsTriggerRecipe({ color: "gold" })}
						>
							markdown
						</Tabs.Trigger>
						<Tabs.Trigger
							value="image"
							class={tabsTriggerRecipe({
								color: "violet",
							})}
						>
							image
						</Tabs.Trigger>
						<Tabs.Trigger
							value="meta"
							class={tabsTriggerRecipe({
								color: "brick",
							})}
						>
							meta
						</Tabs.Trigger>
						<Tabs.Trigger
							value="html"
							class={tabsTriggerRecipe({
								color: "emerald",
							})}
						>
							html
						</Tabs.Trigger>
					</Tabs.List>
					<div class={tabsContentContainerRecipe({ color: color() })}>
						<div class={debugGridRecipe({ color: color() })} />
						<Tabs.Content value="markdown" class={tabsContentClass}>
							<span>
								Fetch the content from a website and convert it to markdown.
							</span>
							<Checkbox
								checked={readability}
								onChange={setReadability}
								label="Readability: Removes irrelevant content but may also eliminate some important information."
							/>
							<Checkbox
								checked={inline}
								onChange={setInline}
								label="Inline: transform images urls into their base64 representation."
							/>
						</Tabs.Content>
						<Tabs.Content value="image" class={tabsContentClass}>
							Take a screenshot of a website and return the image url.
						</Tabs.Content>
						<Tabs.Content value="meta" class={tabsContentClass}>
							Extract the most common metadata from a website and return the
							json.
							<Checkbox
								checked={truncatedBody}
								onChange={setTruncatedBody}
								label="Include an excerpt of the body"
							/>
						</Tabs.Content>
						<Tabs.Content value="html" class={tabsContentClass}>
							<span>Fetch the content from a website and return the html.</span>
							<Checkbox
								checked={readability}
								onChange={setReadability}
								label="Readability: Removes irrelevant content but may also eliminate some important information."
							/>
							<Checkbox
								checked={inline}
								onChange={setInline}
								label="Inline: transform images urls into their base64 representation."
							/>
						</Tabs.Content>
						<Show
							when={props.apiKey}
							fallback={
								<SelectField
									value={targetUrl}
									label="URL"
									onChange={setTargetUrl}
									placeholder="Select a URL…"
									options={DEFAULT_URLS}
								/>
							}
						>
							<TextField
								value={targetUrl}
								onChange={setTargetUrl}
								label="URL"
							/>
						</Show>
						<TextField defaultValue={apiKey()} disabled label="API Key" />
					</div>
				</Tabs>
			</div>

			<div class={rightPaneClass}>
				<h2 class={h2Recipe({ color: "mold" })}>░ preview</h2>
				<div class={tabsClass}>
					<Tabs value={language()} onChange={setLanguage} class={tabsClass}>
						<Tabs.List class={tabsListClass}>
							<Tabs.Trigger
								class={tabsTriggerRecipe({ color: "dark" })}
								value="bash"
							>
								bash
							</Tabs.Trigger>
							<Tabs.Trigger
								class={tabsTriggerRecipe({ color: "dark" })}
								value="js"
							>
								js
							</Tabs.Trigger>
							<Tabs.Trigger
								class={tabsTriggerRecipe({ color: "dark" })}
								value="python"
							>
								python
							</Tabs.Trigger>
							<Tabs.Trigger
								class={tabsTriggerRecipe({ color: "dark" })}
								value="go"
							>
								go
							</Tabs.Trigger>
							<Tabs.Trigger
								class={tabsTriggerRecipe({ color: "dark" })}
								value="ruby"
							>
								ruby
							</Tabs.Trigger>
						</Tabs.List>
						<div
							class={tabsContentContainerRecipe({
								color: "dark",
							})}
						>
							<pre class={preClass}>
								<button
									class={copyToClipboardClass}
									onClick={onCopy}
									type="button"
								>
									<Show when={copied()}>copied!</Show>
									<CopyToClipboard />
								</button>
								<span class={preCodeClass}>{snippet()}</span>
							</pre>
						</div>
						<div
							class={tabsContentContainerRecipe({
								color: "dark",
							})}
						>
							<div class={debugGridRecipe({ color: "dark" })} />
							<Switch>
								<Match when={response.loading}>
									<div class={resultClass}>
										<pre class={resultContentClass}>fetching...</pre>
									</div>
								</Match>
								<Match when={response.error}>
									<div>Error: {response.error.message}</div>
								</Match>
								<Match when={response()}>
									<Switch>
										<Match
											when={handler() === "markdown" || handler() === "html"}
										>
											<div class={resultClass}>
												<pre class={resultContentClass}>
													{response() as string}
												</pre>
											</div>
										</Match>
										<Match when={handler() === "meta"}>
											<div class={resultClass}>
												<pre class={resultContentClass}>
													{JSON.stringify(response() as object, null, 2)}
												</pre>
											</div>
										</Match>
										<Match when={handler() === "image"}>
											<div class={resultClass}>
												<img
													src={response() as string}
													aria-label="screenshot"
												/>
											</div>
										</Match>
									</Switch>
								</Match>
							</Switch>
						</div>
					</Tabs>
				</div>
			</div>
		</section>
	)
}
