import { TextField as KobalteTextField } from "@kobalte/core/text-field"
import type { Accessor, Setter } from "solid-js"
import {
	inputClass,
	inputFieldClass,
	inputFieldWrapperClass,
	labelClass,
} from "./textField.css"

type Props = {
	value?: Accessor<string>
	onChange?: Setter<string>
	defaultValue?: string
	disabled?: boolean
	label: string
}
export function TextField(props: Props) {
	return (
		<KobalteTextField
			value={props.value?.()}
			onChange={props.onChange}
			defaultValue={props.defaultValue}
			disabled={props.disabled}
			class={inputClass}
		>
			<KobalteTextField.Label class={labelClass}>
				{props.label}
			</KobalteTextField.Label>
			<div class={inputFieldWrapperClass}>
				<KobalteTextField.Input class={inputFieldClass} />
			</div>
		</KobalteTextField>
	)
}
